<template>
  <div class="page-content" v-if="zhanhui">
    <div class="zhanhui-details-header">
      <div class="zhanhui-details-header-left">
        <div><img class="zhanhui-details-header-logo" v-attachment-attr:src="zhanhui.logo"></div>
      </div>
      <div class="zhanhui-details-header-right">
        <div class="zhanhui-details-title">
          {{zhanhui.zhmc}}
          <span class="tag" v-if="zhanhui.sshy">{{zhanhui.sshy}}</span>
        </div>
        <div class="zhanhui-details-fields">
          <div class="info-field" style="width: 60%">
            <span class="info-field-label">举办时间：</span>
            <span class="info-field-value">{{formatTime(zhanhui.jbkssj2)}} ~ {{formatTime(zhanhui.jbjssj2)}}</span>
          </div>
          <div class="info-field" style="width: 40%">
            <span class="info-field-label">会议价格：</span>
            <span class="info-field-value">{{zhanhui.hyjg}}</span>
          </div>
          <div class="info-field" style="width: 60%">
            <span class="info-field-label">会议地点：</span>
            <span class="info-field-value">{{zhanhui.city}} {{zhanhui.jbcg}}</span>
          </div>
          <div class="info-field" style="width: 40%">
            <span class="info-field-label">会议规模：</span>
            <span class="info-field-value">{{zhanhui.hygm}}</span>
          </div>
          <div class="info-field" style="width: 100%" v-if="zhanhui.jbdw">
            <span class="info-field-label">主办单位：</span>
            <span class="info-field-value">{{zhanhui.jbdw}}</span>
          </div>
          <div class="info-field" style="width: 100%" v-if="zhanhui.cbdw">
            <span class="info-field-label">承办单位：</span>
            <span class="info-field-value">{{zhanhui.cbdw}}</span>
          </div>
        </div>
      </div>
      <div class="zhanhui-details-header-djs" v-if="pendingDays>0">
        <div>距离开展</div>
        <div class="zhanhui-details-header-djs-number">{{pendingDays}}</div>
        <div>天</div>
      </div>
    </div>
    <el-row>
      <el-col :span="24">
        <block title="会议简介">
          <div v-html="richTextToHtml(zhanhui.zhjj)"></div>
        </block>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Block from "./Block";
import AttachmentAttrDirective from "./AttachmentAttrDirective";
import api from "../api";
import HtmlUtils from "../utils/HtmlUtils";
import moment from "moment";
export default {
  name: "HuiyiDetails",
  components:{Block},
  directives: {
    'attachment-attr': AttachmentAttrDirective
  },
  data() {
    return {
      zhanhui:null
    }
  },
  created() {
    this.init()
  },
  computed:{
    pendingDays(){
      return this.zhanhui&&this.zhanhui.jbkssj?moment(this.zhanhui.jbkssj).diff(moment(),"days"):null
    }
  },
  methods: {
    async init(){
      this.zhanhui=(await api.getZhanhuiById(this.$route.query.id)).data
    },
    richTextToHtml(str){
      return HtmlUtils.richTextToHtml(str)
    },
    formatTime(str){
      if(!str){
        return ''
      }
      return moment(str,'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm')
    }
  }
}
</script>

<style scoped>

</style>
