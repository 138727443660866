<template>
  <div class="page-content" v-if="data">
    <div class="page-tips">数据来源于活动家，已接入北京及上海两个城市信息</div>
    <div class="search-form" style="margin-top: 10px;">
      <div class="search-form-row">
        <div class="search-form-field">
          <div class="search-form-field-label">会议行业：</div>
          <div class="search-form-field-content">
            <collapse collapsed-height="36px">
              <tag-select :tags="industries" v-model="searchForm.sshy" multiple></tag-select>
            </collapse>
          </div>
        </div>
      </div>
      <div class="search-form-row">
        <div class="search-form-field">
          <div class="search-form-field-label">会议地区：</div>
          <div class="search-form-field-content">
            <collapse collapsed-height="36px">
              <div class="area-provinces-select">
                <div class="area-provinces-select-item" v-for="i in areaAndProvinces" :key="i.area" :style="{width:i.width}">
                  <div class="area-provinces-select-area">{{i.area}}：</div>
                  <tag-select class="area-provinces-select-provinces" :tags="i.provinces" v-model="i.selected" multiple></tag-select>
                </div>
              </div>
            </collapse>
          </div>
        </div>
      </div>
      <div class="search-form-row">
        <div class="search-form-field">
          <div class="search-form-field-label">会议时间：</div>
          <div class="search-form-field-content">
            <tag-select :tags="['已结束','排期中','会议开始']" v-model="searchForm.state"></tag-select>
            <el-date-picker style="width: 250px;margin-left: 10px;" clearable
                            v-model="searchForm.dateRange"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期" size="small" unlink-panels
                            end-placeholder="结束日期">
            </el-date-picker>
          </div>
        </div>
      </div>
      <div class="search-form-row">
        <div class="search-form-field">
          <div class="search-form-field-label">会议：</div>
          <div class="search-form-field-content v-center">
            <el-input v-model="searchForm.zhmcLike" style="width: 200px;" size="mini"></el-input>
          </div>
        </div>
        <div class="search-form-field">
          <div class="search-form-field-label">场馆：</div>
          <div class="search-form-field-content v-center">
            <el-input v-model="searchForm.jbcgLike" style="width: 200px;" size="mini"></el-input>
          </div>
        </div>
        <div class="search-form-field">
          <div class="search-form-field-label">主承办方：</div>
          <div class="search-form-field-content v-center">
            <el-input v-model="searchForm.zcbdwLike" style="width: 200px;" size="mini"></el-input>
          </div>
        </div>
      </div>
      <div class="search-form-bottom">
        <el-button type="primary" @click="search">检索</el-button>
        <el-button @click="reset">重置</el-button>
      </div>
    </div>
    <div style="margin-top: 20px;">
      <div class="huiyi-cards">
        <a class="huiyi-card" v-for="item in data.content" :key="item.id" :href="$router.resolve({path:'/huiyi/details',query:{id:item.id}}).href">
          <el-image class="huiyi-card-logo" :src="$api.getAttachmentUrl(item.logo)" lazy fit="fill"></el-image>
          <div class="huiyi-card-fields">
            <div class="huiyi-card-title">{{item.zhmc}}</div>
            <div class="huiyi-card-info">{{item.city}} {{item.jbkssj}}</div>
            <div class="huiyi-card-bottom">{{item.hyjg}}</div>
          </div>
        </a>
      </div>
      <el-pagination layout="prev, pager, next" background :page-size="pageSize"
                     :total="data.totalElements" :current-page="data.number+1"
                     @current-change="loadData">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import api from "../api";
import AttachmentAttrDirective from "./AttachmentAttrDirective";
import TagSelect from "./TagSelect";
import moment from "moment";
import Collapse from "./Collapse";
const DATE_FMT='YYYY-MM-DD';

export default {
  name: "HuiyiList",
  components:{TagSelect,Collapse},
  directives: {
    'attachment-attr': AttachmentAttrDirective
  },
  data(){
    return {
      industries:['IT/技术','IT/技术培训','IT互联网','交通物流','人力资源','人力资源培训','农林牧渔','加工制造','医疗医学','地产商业','地产商业培训','地产建筑','学术','学术/科研','学术/科研培训','学校/教师','学校/教师培训','市场/运营','市场/运营培训','教育培训','文化传媒','服务行业','游学考察','生产管理','生产管理培训','生物/医学','生物/医学培训','经营管理','经营管理培训','职场技能','职场技能培训','能源化工','金融/财务','金融/财务培训','金融财经','其它行业'],
      areaAndProvinces:[
        {
          area:"华东",
          provinces:["山东", "江苏", "上海", "浙江", "福建", "安徽", "江西"],
          selected:[],
          width:'40%',
        },
        {
          area:"华南",
          provinces:["广东", "广西", "海南"],
          selected:[],
          width:'30%',
        },
        {
          area:"西南",
          provinces:["四川", "云南", "重庆", "贵州", "西藏"],
          selected:[],
          width:'30%',
        },
        {
          area:"华北",
          provinces:["河北", "北京", "天津", "内蒙古", "山西"],
          selected:[],
          width:'40%',
        },
        {
          area:"西北",
          provinces:["陕西", "新疆", "甘肃", "青海", "宁夏"],
          selected:[],
          width:'30%',
        },
        {
          area:"华中",
          provinces:["湖南", "湖北", "河南"],
          selected:[],
          width:'30%',
        },
        {
          area:"东北",
          provinces:["辽宁", "吉林", "黑龙江"],
          selected:[],
          width:'40%',
        }
      ],
      searchForm:{
        sshy:[],
        state:null,
        dateRange:null,
        zhmcLike:null,
        jbcgLike:null,
        zcbdwLike:null,
      },
      searchParams:{
        sshy:null,
        province:null,
        state:null,
        startDate:null,
        endDate:null,
        zhmcLike:null,
        jbcgLike:null,
        zcbdwLike:null,
      },
      pageSize:12,
      data:null
    }
  },
  created() {
    this.init()
  },
  methods:{
    async init(){
      if(this.$route.query.state){
        this.searchForm.state=this.$route.query.state
      }
      if(this.$route.query.startDate&&this.$route.query.endDate){
        this.searchForm.dateRange=[
          moment(this.$route.query.startDate,DATE_FMT),
          moment(this.$route.query.endDate,DATE_FMT)
        ]
      }
      if(this.$route.query.area){
        const i=this.areaAndProvinces.find(i=>i.area===this.$route.query.area)
        if(i){
          i.selected=[...i.provinces]
        }
      }
      if(this.$route.query.sshy){
        if(Array.isArray(this.$route.query.sshy)){
          this.searchForm.sshy=this.$route.query.sshy
        }else{
          this.searchForm.sshy=[this.$route.query.sshy]
        }
      }
      await this.search()
    },
    async search(){
      this.searchParams.sshy=this.searchForm.sshy
      const province=this.areaAndProvinces.reduce((acc,v)=>{
        acc.push(...v.selected)
        return acc
      },[])
      if(province.length===0){
        this.searchParams.province=null
      }else{
        this.searchParams.province=province
      }
      if(this.searchForm.state==='已结束'){
        this.searchParams.state='END'
      }else if(this.searchForm.state==='排期中'){
        this.searchParams.state='PENDING'
      }else if(this.searchForm.state==='会议开始'){
        this.searchParams.state='OPENING'
      }else{
        this.searchParams.state=null
      }
      if (this.searchForm.dateRange) {
        this.searchParams.startDate = moment(this.searchForm.dateRange[0]).format(DATE_FMT)
        this.searchParams.endDate = moment(this.searchForm.dateRange[1]).format(DATE_FMT)
      }else{
        this.searchParams.startDate = null
        this.searchParams.endDate = null
      }
      this.searchParams.zhmcLike=this.searchForm.zhmcLike
      this.searchParams.jbcgLike=this.searchForm.jbcgLike
      this.searchParams.zcbdwLike=this.searchForm.zcbdwLike

      await this.loadData(1)
    },
    async reset(){
      this.searchForm.sshy=[]
      this.areaAndProvinces.forEach(i=>i.selected=[])
      this.searchForm.state=null
      this.searchForm.dateRange=null
      this.searchForm.zhmcLike=null
      this.searchForm.jbcgLike=null
      this.searchForm.zcbdwLike=null
    },
    async loadData(page){
      const params={
        ...this.searchParams,
        type:'会议',
        page:page-1,
        size:this.pageSize,
        sort:'jbkssj,DESC'
      }
      this.data=(await api.listZhanhui(params)).data
    }
  }
}
</script>

<style scoped>

</style>
